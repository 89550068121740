body {
    overscroll-behavior-y: none;
}
.canvas-pan > div:nth-child(2) {
	transform: none !important;
	left: 50% !important;
	top: 50% !important;
}

  
@keyframes pulse {
	0% {
	  transform: scale(1.15);
	  opacity: 0; /* Fade in */
	}
	50% {
	  transform: scale(1.45);
	  opacity: .5; /* Fully visible */
	}
	100% {
	  transform: scale(1.15);
	  opacity: 0; /* Fade out */
	} 
}

@keyframes progress-bar {
    0% { left: -4.5vw; }
    100% { left: 100%;}
}
#progressbar {
	background-color: #C1CCC4;
	height: 8px;
	border-radius: 30px;
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%);
	overflow: hidden;
} 
#progressbar::before {
		width: 5vw;
		position:relative;
		left: -4.5vw;
		background-color: #294733;
		content: ' ';
		display: block;
		height: 8px;
		border-radius: 30px;
		animation: 1.5s progress-bar .5s infinite  alternate ease-in-out;

	}

.container-fluid {
	max-width: 1960px;
}

.main-navbar-button{
	padding: 16px 24px;
	height: 59px;
	background: linear-gradient(245.18deg, #06CDE5 7.38%, #0A2FFF 101.15%);
	border-radius: 19px;
}

.sentient-title{
	font-family: din-2014-narrow;
	font-size: 72px;
	font-style: normal;
	font-weight: 700;
	line-height: 100%;
}

.sentient-subtitle{
	font-family: din-2014-narrow;
	font-size: 41px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	color:#294734;
}

.sentient-contenttitle{
	font-family: din-2014-narrow;
	font-size: 25px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	color:#294734;
}

.sentient-content{
	font-family: din-2014-narrow;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	color:#294734;
}

.canvas-pan {
	background:#ebf1ee;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15), 0px 4px 16px rgba(0, 0, 0, 0.10);
	border-radius: 16px;
}

.sentient-underline{
	border-color: #294734 !important;
	border-width: 2px !important;
}

@media screen and (max-width: 1200px) {
	.sentient-title {
		font-size: 52px;
	}
	.sentient-subtitle {
		font-size: 24px;
	}
}

@media screen and (max-width: 1024px) {
	.navbar-brand img {
		max-width: 250px !important;
	}
	.sentient-title {
		font-size: 48px;
	}
	.sentient-subtitle {
		font-size: 20px;
	}
}
@media screen and (max-width: 768px) {

	.navbar-brand {
		max-width: 50% !important;
	}
}
/* ---landing page---- */
.sentient-landing {
	background-image: url('/src/assets/images/background/background-modern.jpg');
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: right center;
}

.sentientbtn-pan {
	background:#294734;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 16px rgba(0, 0, 0, 0.15);
	border-radius: 16px;
}

.sentientbtn-pan:hover {
	opacity: 0.7;
	cursor: pointer;
}

.sentientbackbtn-pan {
	background:#294734;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 16px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
}

.sentientbackbtn-pan:hover {
	opacity: 0.7;
	cursor: pointer;
}
/* ------selfloor page--- */

.sentient-selbedroom {
	background-image: url('/src/assets/images/background/background-modern.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right center;
	min-height: 100%;
}

.sentient-buy-pan {
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 16px rgba(0, 0, 0, 0.15);
	border-radius: 16px;
	background-color: #ffffff;
	border: 2px solid #FFFFFF;
}

.sentient-buy-pan:hover {
	opacity: 0.8;
	cursor: pointer;
	border: 2px solid #294734;
}

.product-height {
	height: 40vh;
}

@media only screen and (max-width: 991px) {
	.product-height {
		width: auto;
		height: auto;
	}
}

/* -----main page----- */
.sentient-lrsection{
	width: 29vw;
}
.sentient-centersection{
	width: 42vw;
}

@media screen and (max-width: 1025px) {
	.sentient-lrsection{
		width: 90vw;
	}
	.sentient-centersection{
		width: 90vw;
	}
}

.sentient-zoom {
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), 0px 4px 16px rgba(0, 0, 0, 0.15);
	border-radius: 16px;
	background-color: #ffffff;
}

.sentient-zoom:hover {
	/* filter: grayscale(70%); */
	background-color: #20f46d;
  	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.sentient-gray {
	/* filter: grayscale(70%); */
	color: gray;
}

/* ---mainview page--- */
